<template>
    <v-app v-if="show">

        <v-snackbar top color="success" v-model="show_new_market_request">
            <v-icon dark>mdi-information</v-icon>
            <p>A product has been requested</p>
            <v-icon dark @click="show_new_market_request = false">mdi-close</v-icon>
        </v-snackbar>

        <v-navigation-drawer
                v-model="drawer"
                app
        >
            <v-row justify="center">
                <img
                    @click="$router.push({name: 'home'})"
                    class="my-1 mt-2"
                    style="height: 54px; cursor: pointer"
                    src="@/assets/imgs/akoko_market_logo.png"
                />
            </v-row>

            <v-divider/>
            <v-list class=" px-4 v-list--rounded">
                <template v-for="item in items">
                    <v-row
                        v-if="item.heading"
                        :key="item.heading"
                        align="center"
                    >
                        <v-col cols="6">
                            <v-subheader v-if="item.heading">
                                {{ item.heading }}
                            </v-subheader>
                        </v-col>
                        <v-col
                                cols="6"
                                class="text-center"
                        >
                            <a
                                    href="#!"
                                    class="body-2 black--text"
                            >EDIT</a>
                        </v-col>
                    </v-row>
                    <v-list-group
                            v-else-if="item.children"
                            :key="item.text"
                            v-model="item.model"
                            :prepend-icon="item.model ? item.icon : item['icon-alt']"
                            append-icon=""
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item
                                v-for="(child, i) in item.children"
                                :key="i"
                                link
                        >
                            <v-list-item-action v-if="child.icon">
                                <v-icon>{{ child.icon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ child.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item
                            @click="$router.push({name: item.route_name})"
                            :class="{ 'primary' : $route.name === item.route_name}"
                            v-else
                            :key="item.text"
                            link
                            class=" mt-5"
                    >
                        <v-list-item-action>
                            <v-icon :class="{'white--text' : $route.name === item.route_name}">{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title :class="{'white--text' : $route.name === item.route_name}">
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar
            flat
            clipped="$vuetify.breakpoint.lgAndUp"
            app
            class="elevation-1"
        >
            <v-app-bar-nav-icon color="primary" @click.stop="drawer = !drawer" />
            <v-spacer />
            <v-btn :to="{name : 'home'}" :icon="isMobile" rounded color="primary">
                <v-icon :left="!isMobile" >mdi-home</v-icon> {{ isMobile ? '' : 'Back Home' }}
            </v-btn>
            <v-btn @click="logout" icon color="red">
                <v-icon>mdi-power-standby</v-icon>
            </v-btn>
        </v-app-bar>
        <v-content class="px-3 pt-1">
            <transition name="fade-transition" mode="out-in">
                <router-view></router-view>
            </transition>
        </v-content>
        <v-footer
            color="primary"
            padless
        >
            <v-row
                justify="start"
                no-gutters
            >
                <v-btn
                    :to="{name: link.route_name}"
                    v-for="link in items"
                    :key="link.route_name"
                    color="white"
                    text
                    rounded
                    :small="!!isMobile"
                    class="my-2"
                >
                    {{ link.text }}
                </v-btn>
                <v-col
                    class="primary lighten-1 py-4 text-center white--text"
                    cols="12"
                >
                    <span class="font-weight-light copyright">
      &copy;
      {{ (new Date()).getFullYear() }}
      <a
          href="https://twitter.com/akokomarket"
          target="_blank" style="text-decoration: none; color:white;font-size: 20px"><b>Akokomarket</b>
      </a>
      <v-icon
          color="tertiary"
          size="17">mdi-egg</v-icon>
      your online livestock marketplace !
    </span>
                </v-col>
            </v-row>
        </v-footer>
    </v-app>
</template>

<script>
    import Crud from "../../../gql/PrismaCrud.gql";
    const MarketRequest = new Crud('marketRequest')
    import CoreToolbar from '../../../components/Admin/core/Toolbar'
    import CoreDrawer from '../../../components/Admin/core/Drawer'
    export default {
        name: "Dashboard",
        components: {CoreToolbar, CoreDrawer},
        metaInfo() {
            return {
                title: "Akokomarket Admin Dashboard",
            };
        },
        data(){
            return {
                show_new_market_request: false,
                show: false,
                dialog: false,
                drawer: null,
                user: this.$store.getters['auth/getUserData'],
                items: [
                    { icon: 'mdi-view-dashboard', text: 'Dashboard', route_name: 'agency-dashboard'},
                    { icon: 'mdi-cart', text: 'Market Request', route_name: 'agency-manage-market-request'},
                    // { icon: 'mdi-account-multiple', text: 'Customers', route_name: 'agency-manage-customers'},
                    { icon: 'mdi-home', text: 'Depot', route_name: 'agency-manage-depot'},
                    { icon: 'mdi-cash', text: 'Transactions', route_name: 'agency-manage-transactions'},
                    // { icon: 'mdi-chart-line', text: 'Sell Survey', route_name: 'agency-manage-sell-survey'},
                    { icon: 'mdi-tag', text: 'Product', route_name: 'agency-manage-products'},
                    { icon: 'mdi-cog', text: 'Settings', route_name: 'agency-settings'},
                ],
            }
        },
        methods:{
            logout(){
                this.firebase.auth().signOut()
                this.$router.push("/");
                this.$store.commit('setSnack', { color: 'green', status_msg: 'Success!', added_msg: 'Logged Out' })
            }
        },
        created(){
            this.firebase.auth().onAuthStateChanged(user=>{
                if(!this.user)
                    return this.$router.push({name: 'home'})
                    this.show = true

              // else if(!this.user.company){
                //         this.$router.push({name: 'home'})
                //     }
                // else{
                //     MarketRequest.subscribeToMore(`{
                //             id
                //         }`, {
                //         where: {
                //             products_some: {
                //                 product: {
                //                     company: {
                //                         id_in: this.user.company.id
                //                     }
                //                 }
                //             }
                //         }
                //     }, res=>{
                //         console.log({res})
                //         if(res.data.marketRequest.mutation === "CREATED"){
                //             console.log({new_request : res})
                //             this.show_new_market_request = true
                //         }
                //     }, error=>{
                //         console.error({'new_request': error})
                //     })
                //
                // }
            })
        }
    };
</script>

<style>
</style>
